<template>
    <div class="text-nowrap">
        <Icon :v="iconName" />
        <span><T>nouns.{{ longIdentifier }}</T></span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import type { genders } from '../src/classes.ts';

export default defineComponent({
    props: {
        gender: { required: true, type: String as PropType<typeof genders[number]> },
    },
    computed: {
        iconName(): string {
            const iconNames = {
                masc: 'mars',
                fem: 'venus',
                neutr: 'neuter',
            };
            return iconNames[this.gender];
        },
        longIdentifier(): string {
            const longIdentifiers = {
                masc: 'masculine',
                fem: 'feminine',
                neutr: 'neuter',
            };
            return longIdentifiers[this.gender];
        },
    },
});
</script>
